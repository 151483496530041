<script setup lang="ts">
import { DataLayerLocationsEnum } from '~/composables/useDataLayer'
import { DataLayerEventsEnum } from '~/types'

const events = useDataLayer()
</script>

<template>
  <slot
    :events="events"
    :events-list="DataLayerEventsEnum"
    :locations="DataLayerLocationsEnum"
    :track-event="events.trackEvent"
  />
</template>
